const analytics = {
  // 获取轮播图
  getBannerList() {
    let data = {
      orderByColumn: "weight",
      isAsc: "desc",
      enable: 1
    };
    return this.get(`/home/open/photo/list`, data);
  },
  // 精彩短剧列表
  getHomeVideoList(data) {
    return this.get(`/home/open/video/list`, data);
  },
  // 获取当前剧播放列表信息
  getVideoSeries(videoId) {
    return this.get(`/home/open/series/${videoId}`);
  }
};
export default analytics;
