import axios from "axios";

/**
 * Unified processing of errors after request failure
 * @param {Number} status Status code of request failure
 */
const errorHandle = (status, other) => {
  // Status code
  switch (status) {
    case 401:
      console.log("401");
      break;

    case 403:
      console.log("403");
      break;
    case 404:
      console.log("404");
      break;
    default:
      console.log(other);
  }
};

// Create an Axios instance
const instance = axios.create({
  timeout: 1000 * 30
});

// set post headers
instance.defaults.headers.post["Content-Type"] = "application/json";

// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  config => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
    const token = "xxx";
    token && (config.headers.Authorization = token);
    return config;
  },
  error => Promise.error(error)
);

// 响应拦截器
instance.interceptors.response.use(
  // 200
  res => {
    return res.status === 200
      ? Promise.resolve(res.data)
      : Promise.reject(res.data);
  },

  // error
  error => {
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message);
      return Promise.reject(response);
    }
    console.log("Result of request failure:", error);
    console.log(
      "The request timed out. Please check whether the network is connected!"
    );
  }
);

export default instance;
