import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import api from "./api/api.js";
import store from "./store";
import "./assets/scss/reset.scss";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";

import SeriesPreview from "@/components/SeriesPreview.vue";

Vue.use(ElementUI);

Vue.component("SeriesPreview", SeriesPreview);

Vue.prototype.$api = api;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
