import axios from "./interceptor.js";
import home from "./home";

const reqUrl = "http://www.huadan.com/prod-api";

const apiObj = {
  get: function(url, data) {
    return axios({
      method: "get",
      url: reqUrl + url,
      params: data,
      data: data,
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    });
  },

  post: function(url, data = {}) {
    return axios({
      method: "post",
      url: reqUrl + url,
      data: data,
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    });
  },
  postFile: function(url, data) {
    return axios({
      method: "post",
      url: reqUrl + url,
      data: data,
      cache: false,
      processData: false,
      contentType: false,
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    });
  },

  postUrl: function(url, set) {
    return axios({
      method: "post",
      url: reqUrl + url + "?" + set.type + "=" + set.data,
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    });
  },
  postParam: function(url, data) {
    return axios({
      method: "post",
      url: reqUrl + url,
      params: data,
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    });
  },
  delete: function(url, data) {
    return axios({
      method: "delete",
      url: reqUrl + url,
      params: data,
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    });
  },
  getForm: function(url, data) {
    return axios({
      method: "post",
      url: reqUrl + url,
      params: data,
      data: data,
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    });
  }
};

const api = Object.assign({}, apiObj, home);
export default api;
