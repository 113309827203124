<template>
  <div class="m-video" :style="`width: ${width}px; height: ${height}px;`">
    <video-player
      id="my-video"
      ref="videoPlayer"
      class="vjs-custom-skin"
      :options="playerOptions"
      :poster="seriesImage"
    />
  </div>
</template>
<script>
import "video.js/dist/video-js.css";
// import "vue-video-player/src/custom-theme.css";
//引入hls.js
import "videojs-contrib-hls";
// video-player
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  components: {
    videoPlayer
  },
  props: {
    seriesImage: {
      // 视频文件url，必传，支持网络地址 https 和相对地址 require('@/assets/files/Bao.mp4')
      type: String,
      required: true,
      default: null
    },
    videoUrl: {
      // 视频文件url，必传，支持网络地址 https 和相对地址 require('@/assets/files/Bao.mp4')
      type: String,
      required: true,
      default: null
    },
    videoCover: {
      // 视频封面url，支持网络地址 https 和相对地址 require('@/assets/images/Bao.jpg')
      type: String,
      default: null
    },
    width: {
      // 视频播放器宽度
      type: Number,
      default: 800
    },
    height: {
      // 视频播放器高度
      type: Number,
      default: 450
    },
    /*
      由于目前在最新版的Chrome浏览器（以及所有以Chromium为内核的浏览器）中，
      已不再允许自动播放音频和视频。就算你为video或audio标签设置了autoplay
      属性也一样不能自动播放！
      本组件解决方法：设置视频 autoplay 时，自动将视频设置为静音 muted: true 即可实现自
      动播放，然后用户可以使用控制栏开启声音，类似某宝商品自动播放的宣传视频逻辑
    */
    autoplay: {
      // 视频就绪后是否马上播放
      type: Boolean,
      default: false
    },
    controls: {
      // 是否向用户显示控件，比如进度条，全屏
      type: Boolean,
      default: true
    },
    loop: {
      // 视频播放完成后，是否循环播放
      type: Boolean,
      default: false
    },
    muted: {
      // 是否静音
      type: Boolean,
      default: false
    },
    preload: {
      // 是否在页面加载后载入视频，如果设置了autoplay属性，则preload将被忽略；
      type: String,
      default: "auto" // auto:一旦页面加载，则开始加载视频; metadata:当页面加载后仅加载视频的元数据 none:页面加载后不应加载视频
    },
    showPlay: {
      // 播放暂停时是否显示播放器中间的暂停图标
      type: Boolean,
      default: false
    },
    playWidth: {
      // 中间播放暂停按钮的边长
      type: Number,
      default: 96
    },
    zoom: {
      // video的poster默认图片和视频内容缩放规则
      type: String,
      default: "none" // none:(默认)保存原有内容，不进行缩放; fill:不保持原有比例，内容拉伸填充整个内容容器; contain:保存原有比例，内容以包含方式缩放; cover:保存原有比例，内容以覆盖方式缩放
    }
  },
  watch: {
    videoUrl(news, old) {
      this.playVideo(news);
    }
  },
  data() {
    return {
      originPlay: true,
      vplay: false,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        controls: true,
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        controlBar: {
          timeDivider: true,
          remainingTimeDisplay: false,
          playToggle: {},
          progressControl: {},
          fullscreenToggle: {},
          volumeMenuButton: {
            inline: false,
            vertical: true
          }
        }
        // poster: ''
      }
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    }
  },
  mounted() {
    if (this.showPlay) {
      this.$refs.veo.addEventListener("pause", this.onPause);
      this.$refs.veo.addEventListener("playing", this.onPlaying);
    }
    if (this.autoplay) {
      this.vplay = true;
      this.originPlay = false;
    }
    // 自定义设置播放速度
    // this.$refs.veo.playbackRate = 2
  },
  methods: {
    playVideo: function(source) {
      const video = {
        withCredentials: false,
        type: "application/x-mpegurl",
        src: source
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      // this.player.load()
      this.player.play();
    },

    onPlay() {
      console.log("click");
      this.vplay = true;
      this.originPlay = false;
      this.$refs.veo.play();
    },
    onPause() {
      this.vplay = false;
      console.log("pause");
      this.$once("hook:beforeDestroy", function() {
        removeEventListener("pause", this.onPause);
      });
    },
    onPlaying() {
      this.vplay = true;
      console.log("playing");
      // 自动清理自己，避免内存泄漏
      this.$once("hook:beforeDestroy", function() {
        removeEventListener("playing", this.onPlaying);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.m-video {
  display: inline-block;
  position: relative;
  width: 100% !important;
  height: 100% !important;
  background: #000;
  cursor: pointer;

  #my-video {
    height: 100% !important;
  }

  &:hover {
    .u-play {
      opacity: 0.9;
    }
  }

  .u-play {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    fill: none;
    color: #fff;
    pointer-events: none;
    opacity: 0.7;
    transition: opacity 0.3s;
  }

  .hidden {
    display: none;
  }
}
</style>
