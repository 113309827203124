import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isSignIn: 0,
    token: "55555555555555"
  },
  mutations: {
    changIsSignIn(state, n) {
      state.isSignIn = n;
    },
    setToken(state, val) {
      state.token = val;
    }
  }
});
